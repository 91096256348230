import { gql } from '@apollo/client';

export const getIndividualSecurityGroups = gql`
  query getIndividualSecurityGroups($memberId: String!) {
    securityGroups: getIndividualSecurityGroups(memberId: $memberId) {
      Name
      TermAccessStatus
      Roles {
        Name
        StartDate
        IsDelegated
        RoleEventKey
        IsUnverified
        Key
      }
      Organizations {
        Name
        Id
        Type
        Key
      }
      Key
    }
  }
`;
