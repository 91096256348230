import { useLazyQuery } from '@apollo/client';

import { getIndividualSecurityGroups } from '../queries';

import {
  GetIndividualSecurityGroupsQuery,
  GetIndividualSecurityGroupsQueryVariables,
} from '@typings/operations';

export const useGetIndividualSecurityGroups = () =>
  useLazyQuery<
    GetIndividualSecurityGroupsQuery,
    GetIndividualSecurityGroupsQueryVariables
  >(getIndividualSecurityGroups);
