import { useMutation } from '@apollo/client';

import { cancelSubscriptionForMember } from '@repositories/my-donations';

import {
  CancelSubscriptionForMemberMutation,
  CancelSubscriptionForMemberMutationVariables,
} from '@typings/operations';

export const useCancelSubscriptionForMember = () =>
  useMutation<
    CancelSubscriptionForMemberMutation,
    CancelSubscriptionForMemberMutationVariables
  >(cancelSubscriptionForMember);
