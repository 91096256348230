import {
  IndividualSecurityGroupsResult,
  SecGroupOutput,
} from '@domain/my-donations/constants';

export const getTransformedSecurityGroups = (
  secGroups: IndividualSecurityGroupsResult[]
): SecGroupOutput[] => {
  if (!secGroups || secGroups.length === 0) return [];
  return secGroups
    .filter(
      (group): group is IndividualSecurityGroupsResult =>
        !!group && group.Organizations && group.Organizations.length > 0
    )
    .map(group => {
      const clubs = (group.Organizations || [])
        .filter((org): org is NonNullable<typeof org> => !!org)
        .map(org => ({
          club_name: org.Name ?? '',
          club_id: org.Id ?? 0,
          club_type: org.Type ?? '',
        }));
      return {
        sec_group_name: group.Name ?? '',
        is_current_term: group.TermAccessStatus === 'Current',
        clubs,
      };
    });
};
