import { gql } from '@apollo/client';

export const cancelSubscriptionForMember = gql`
  mutation CancelSubscriptionForMember(
    $SubscriptionId: String!
    $MerchantConfig: String!
  ) {
    cancelSubscriptionForMember(
      SubscriptionId: $SubscriptionId
      MerchantConfig: $MerchantConfig
    ) {
      Message
    }
  }
`;
